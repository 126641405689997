import React from "react";
import ReactMarkdown from "react-markdown";
import IntersectionBox from "../animations/IntersectionBox";

function ProcessDetails({ header, description, img }) {
  return (
    <div className="process-details row">
      <div className="info col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <IntersectionBox>
          <h2 className="header">{header}</h2>
        </IntersectionBox>
        <IntersectionBox>
          <div className="desc">
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
        </IntersectionBox>
      </div>
      <figure className="process-image col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <IntersectionBox>
          <img src={img} alt={header} />
        </IntersectionBox>
      </figure>
    </div>
  );
}

ProcessDetails.defaultProps = {
  header: "Process Header Details",
  description:
    "Custiv’s innovative platform allows you to obtain quick quotes for any CNC machining requirement, from one part to thousands of parts. Our extended partner facilities network of extensively vetted 200+ machines enable you to get quick manufacturing and shipment of your machined parts. Our engineers with expertise of over 20 years in CNC machining review your parts and provide DfM feedback making sure that your parts are manufactured hassle free. You can request for inspection reports and material test certificates in one click. We also have multiple finish & post-processing.",
  img: "/images/blog_img.png",
};

export default ProcessDetails;
