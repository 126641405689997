import { motion } from "framer-motion";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "../../../components/modal";

const childCardVariant = {
  hover: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

function MaterialCard({
  title,
  visible_description,
  expanded_description,
  grades,
  img,
  index,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <motion.div
        className="card"
        whileHover={{ scale: 1.05 }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <p className="material-name">{title} </p>
        <div
          className="image"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "150px",
            backgroundPosition: "center",
          }}
        ></div>
        {/* <motion.div
          className="learn-more"
          whileHover="hover"
          // initial="hidden"
          variants={childCardVariant}
        >
          Learn More
        </motion.div> */}
      </motion.div>

      <Modal open={open} setOpen={setOpen}>
        <div className="material-modal">
          <div
            className="image"
            style={{
              backgroundImage: img,
              backgroundSize: "cover",
            }}
          >
            <img src={img} alt={title} />
          </div>
          <div className="content">
            <h4 className="header">{title}</h4>
            <div className="description">
              <ReactMarkdown>{expanded_description}</ReactMarkdown>
            </div>
            {grades.length > 0 && (
              <div className="grades">
                <div>
                  <div className="header">Grades</div>
                </div>
                <div>
                  <ul>
                    {grades.map((grade, index) => {
                      if (grade !== "") {
                        return <li>{grade}</li>;
                      }
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

MaterialCard.defaultProps = {
  title: "Post Processing",
  visible_description: "Post Processing Description",
  expanded_description: "Post Processing Expanded Description",
  grades: [],
  img: "",
  index: 0,
};

function Materials({ title, materials }) {
  return (
    <div className="materials-avail-sec">
      <h3 className="materials-head">{title}</h3>

      <div className="materials-container container-fluid">
        <div className="row materials-row">
          {materials.map((material, index) => (
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <MaterialCard
                title={material.material_name}
                visible_description=""
                expanded_description={material.material_description}
                grades={
                  material.material_grades
                    ? material.material_grades.split("\n")
                    : []
                }
                img={material.material_image?.image?.url}
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Materials.defaultProps = {
  materials: [
    {
      material_name: "Aluminium",
      material_grades: "Some Random Description",
      material_description_or_grades: "Description or goods",
      material_image: {
        image: {
          publicURL: "",
        },
      },
    },
  ],
};

export default Materials;
