import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import IntersectionBox from "../animations/IntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import { zoomIn } from "../animations/variants";

const videoSrc = [
  "/videos/Upload Part.mp4",
  "/videos/DFM.mp4",
  "/videos/View Quote.mp4",
  "/videos/Order Tracking.mp4",
];

function Screen({ header, description, img, vid }) {
  const fadeInUp = {
    show: {},
  };

  return (
    <motion.div
      className="screen"
      initial={{
        scale: 0.8,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
    >
      <p className="card-head"> {header} </p>
      <p className="card-desc">
        <TrailingParagraph paragraph={description} />
      </p>
      <IntersectionBox threshold={0.1} variants={zoomIn}>
        <video
          muted
          playsInline
          autoPlay
          loop
          id="platform-section-background-video"
          className="platform-section-background-video"
          poster={img}
          preload="auto"
          src={vid}
        ></video>
      </IntersectionBox>
    </motion.div>
  );
}

Screen.defaultProps = {
  header: "Online Upload & Configuration",
  description:
    "Upload your CAD files and engineering drawings. Specify your required manufacturing process, materials and all associated options like finishing and inspection online",
  img: "/images/manage-parts1.png",
  vid: "/videos/platform-recording-frag.mp4",
};

function PlatformSection({ title }) {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        index {
          platform_advantage {
            description_text
            heading_text
          }
        }
      }
    }
  `);
  const node = data.strapi.index;

  const [activeButton, setActiveButton] = useState(0);

  return (
    <div className="platform-section">
      <div
        className="section-5 inner-section triggerAnimation animated"
        data-animate="fadeInUp"
      >
        <div className="content">
          <h3 className="platform-head">
            <TrailingParagraph paragraph={title} />
          </h3>
          <IntersectionBox>
            <div className="big-tabs">
              {node.platform_advantage.map((tab, index) => (
                <a
                  className={`big-tab ${
                    activeButton === index ? "selected" : ""
                  }`}
                  onClick={() => {
                    setActiveButton(index);
                  }}
                >
                  {tab.heading_text}
                </a>
              ))}
            </div>
          </IntersectionBox>
          <IntersectionBox threshold={0.2}>
            <Screen
              header={node.platform_advantage[activeButton].heading_text}
              description={
                node.platform_advantage[activeButton].description_text
              }
              key={activeButton}
              vid={videoSrc[activeButton]}
            />
          </IntersectionBox>
        </div>
      </div>
    </div>
  );
}

PlatformSection.defaultProps = {
  title: "Manage your Parts, RFQs and Orders in a single platform.",
};

export default PlatformSection;
