import React from "react";
import TrailingParagraph from "../animations/TrailingParagraph";
import IntersectionBox from "../animations/IntersectionBox";
import { fadeInFromRightWithDelay } from "../animations/variants";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { FaCheckCircle } from "react-icons/fa";

function QualitySection({ title, description, cert_list, img }) {
  return (
    <div className="service-quality-assurance container-fluid">
      <div className="row quality-assurance">
        <div className="quality-info triggerAnimation animated col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <h2 className="quality-head">
            <TrailingParagraph paragraph={title} />
          </h2>
          <p className="quality-desc">
            <TrailingParagraph paragraph={description} />
          </p>
          <p className="quality-cert"> </p>
          <div className="row certification-list">
            {cert_list.map((cert, index) => (
              <IntersectionBox threshold={0.8}>
                <p className="quality-cert">
                  <FaCheckCircle />
                  {cert}
                </p>
              </IntersectionBox>
            ))}
          </div>
          <IntersectionBox>
            <Link to="/quality-assurance">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="clippy_button contact-button"
              >
                {" "}
                Explore{" "}
              </motion.button>
            </Link>
          </IntersectionBox>
        </div>
        <div
          className="quality-img triggerAnimation animated col-lg-6 col-md-6 col-sm-6 col-xs-12"
          data-animate="fadeInUp"
        >
          <IntersectionBox>
            <div className="qual-assur-img">
              <img src={img} alt="Custiv Quality Assurance" />
            </div>
          </IntersectionBox>
        </div>
      </div>
    </div>
  );
}

QualitySection.defaultProps = {
  title: "Quality Assurance",
  description:
    "Our facilities are well equipped with cutting edge manufacturing technology and have years of experience in manufacturing production. Our facilities are certified under multiple certifications.",
  cert_list: ["ISO 9001", "AS9100", "IATF 16949", "OHSAS 18001"],
  img: "/images/quality_assur_img.png",
};

export default QualitySection;
